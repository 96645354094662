<template>
  <div id="revenueFilterreven">
    <!-- Revenue Filter -->
    <div class="eventFilter">
      <!-- Header -->
      <div class="filterHeader">
        <!-- Select Event -->
        <div class="filterHeaderColumn">
          <label>Select Revenue Event</label>
          <!-- Loader for fetching data -->
          <div class="loader" v-if="fetchingData">
            <beat-loader :loading="fetchingData" color="rgb(220, 223, 230)" size="8px"></beat-loader>
          </div>
          <el-select class="dropdown" v-if="!fetchingData" size="small" v-model="revenueEventName" filterable @change="onEventSelect()" placeholder="Revenue Event">
            <el-option v-for="event in eventList" :key="event" :label="event" :value="event"> </el-option>
          </el-select>
        </div>
        <!-- Select Revenue Property -->
        <div class="filterHeaderColumn" v-if="revenueEvent">
          <label>Select Revenue Property</label>
          <el-select class="dropdown" v-if="!fetchingData" size="small" v-model="revenueEvent.property" filterable placeholder="Revenue Property">
            <el-option v-for="event in propertyList" :key="event.name" :label="event.name" :value="event.name" :disabled="event.type != 'number'"> </el-option>
          </el-select>
        </div>
        <!-- Select GroupBy Property -->
        <div class="filterHeaderColumn" v-if="revenueEvent && showGroupBy">
          <label>Group By</label>
          <el-select class="dropdown" v-if="!fetchingData" size="small" v-model="revenueEvent.groupBy" filterable clearable placeholder="Revenue Property">
            <el-option v-for="event in propertyList" :key="event.name" :label="event.name" :value="event.name"> </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <!-- Property Filters -->
    <div class="propertyFilterList" v-if="revenueEvent && revenueEvent.filters.length > 0">
      <div class="propertyFilter" v-for="(subFilter, index1) in revenueEvent.filters" :key="index1">
        <span style="font-size: 11px; background: #003b61; color: white; line-height: 23px; border-radius: 5px; height: 23px"> &nbsp; <span v-if="index1 != 0">and &nbsp;</span>where &nbsp; </span>

        <!-- Select Filter Property -->
        <el-select class="smallDropdown" size="mini" v-model="subFilter.propertyName" filterable @change="onFilterPropertyChange(subFilter)" placeholder="Select Property">
          <el-option v-for="(property, index) in propertyList" :key="index" :label="property.name" :value="property.name"> </el-option>
        </el-select>

        <!-- Select Comparision Type -->
        <el-select class="smallDropdown" size="mini" v-if="subFilter.comparisionTypeList" v-model="subFilter.comparisionType" filterable placeholder="Select Event Property">
          <el-option v-for="(compType, index2) in subFilter.comparisionTypeList" :key="index2" :label="compType.label" :value="compType.key"></el-option>
        </el-select>

        <!-- Input Field List Based on types -->
        <div class="filterInputContainer" v-if="subFilter.comparisionType && subFilter.comparisionType != 'is not null' && subFilter.comparisionType != 'is null'">
          <!-- Render Date For Date Type -->
          <div v-if="subFilter.propertyInfo.type == 'date'">
            <el-date-picker size="mini" v-model="subFilter.value" type="datetime" placeholder="Select date and time"> </el-date-picker>
          </div>

          <!-- Render Number input for number type -->
          <div v-if="subFilter.propertyInfo.type == 'decimal' || subFilter.propertyInfo.type == 'number' || subFilter.propertyInfo.type == 'bigint'">
            <el-input size="mini" placeholder="Enter value" v-model="subFilter.value"></el-input>
          </div>

          <!-- Render Searchable String for string type  -->
          <div v-if="subFilter.propertyInfo.type == 'string'">
            <el-autocomplete autocomplete="off" size="mini" placeholder="Enter search term" @focus="subFilterForSuggestion = subFilter" v-model="subFilter.value" :fetch-suggestions="fetchEventPropertySuggestion" clearable></el-autocomplete>
          </div>

          <!-- Boolean - Yes/No dropdown -->
          <div v-if="subFilter.propertyInfo.type == 'boolean'">
            <el-select size="mini" v-model="subFilter.value">
              <el-option label="True/Yes" value="true"></el-option>
              <el-option label="False/No" value="false"></el-option>
            </el-select>
          </div>
        </div>

        <!-- Delete Button -->
        <el-button class="addFilterButton deleteBtn" icon="el-icon-delete" @click="deletePropertyFilter(revenueEvent.filters, index1)" type="text" size="mini"></el-button>
      </div>
    </div>

    <!-- Add Filter Column -->
    <el-button size="mini" type="info" plain icon="el-icon-plus" v-if="revenueEvent" @click="addPropertyFilter">Add Filter</el-button>
  </div>
</template>

<style lang="scss" src="./revenueFilter.scss"></style>

<script>
import revenueFilterComponent from './revenueFilterComponent';
export default revenueFilterComponent;
</script>
