<template>
  <div id="revenuePage">
    <!-- Header -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <div class="bg-gray-50 border-b border-b-gray-200 px-3 py-2 leading-7 text-md">Event Trends</div>

      <!-- Query Builder -->
      <div class="queryBuilder">
        <div class="builder pl-1">
          <!--Event Property Filter -->
          <div class="column">
            <RevenueFilterComponent title="Select Revenue Event" ref="revenueFilterRef"></RevenueFilterComponent>
          </div>

          <!-- User Filter -->
          <div class="column" style="border-left: 1px solid #eef0f4; display: none">
            <UserPropertyFilterComponent v-bind:filterTitle="' Specify Segment'" ref="userFilterComponent"></UserPropertyFilterComponent>
          </div>
        </div>

        <div class="applyQuery pl-3">
          <el-button type="primary" @click="fetchGraphData()" :loading="fetchingGraphData">Generate Report</el-button>
        </div>
      </div>
    </div>

    <!-- Graph -->
    <div class="queryResult">
      <!-- Header -->
      <div class="header" v-if="$refs.revenueFilterRef && $refs.revenueFilterRef.revenueEvent.property">
        <!-- Chart Type -->
        <el-radio-group v-model="chartType" size="mini" @change="fetchGraphData()">
          <el-radio-button label="dynamic_timeline_graph">
            <i class="fa fa-line-chart" aria-hidden="true"></i>
          </el-radio-button>
          <el-radio-button label="dynamic_bar_graph">
            <i class="fa fa-bar-chart" aria-hidden="true"></i>
          </el-radio-button>
        </el-radio-group>

        <!-- Time Range -->
        <span class="rightSideOptions datepickerInFilter">
          <el-select v-model="timingFilter" v-if="timingFilter != ''" size="mini" placeholder="Select">
            <el-option v-for="(value, key) in chartBuilderTimingOptions" :key="key" :label="value" :value="key"> </el-option>
          </el-select>
          <el-date-picker v-if="timingFilter == ''" v-model="dateFilterValues" size="mini" type="datetimerange" format="dd-MM-yyyy hh:mm:ss A" @change="fetchGraphData()"></el-date-picker>
        </span>

        <!-- Timing Group -->
        <el-select class="rightSideOptions" v-model="groupByDuration" size="mini" placeholder="Select" @change="fetchGraphData()">
          <el-option v-for="item in groupByDurationList" :key="item.value" :label="item.key" :value="item.value"> </el-option>
        </el-select>
      </div>

      <!-- Loader -->
      <div class="loadingDiv" v-if="fetchingGraphData" v-loading="true" style="min-height: 300px"></div>

      <!-- Chart -->
      <div class="chartContainer" v-if="!fetchingGraphData && (dynamicTimelineChartOptions || dynamicBarChartOptions)">
        <div class="pinToDashboard" @click="showSelectDashboardPopup()">
          <el-tooltip class="item" effect="dark" content="Pin to dashboard" placement="left">
            <i class="fa fa-thumb-tack" aria-hidden="true"></i>
          </el-tooltip>
        </div>
        <div v-if="chartType == 'dynamic_timeline_graph' && dynamicTimelineChartOptions != null">
          <DynamicTimelineGraphComponent ref="timelineGraph" v-bind:graph="dynamicTimelineChartOptions"></DynamicTimelineGraphComponent>
        </div>
        <div v-else-if="chartType == 'dynamic_horizontal_bar_graph' && horizontalBarChartOptions != null">
          <HorizontalBarGraph ref="hbarGraph" v-bind:graph="horizontalBarChartOptions"></HorizontalBarGraph>
        </div>
        <div v-else-if="chartType == 'dynamic_bar_graph' && dynamicBarChartOptions != null">
          <DynamicBarGraph ref="barGraph" v-bind:graph="dynamicBarChartOptions"></DynamicBarGraph>
        </div>
      </div>

      <!-- Chart Data As Table -->
      <el-card class="box-card tableCotnainer" v-if="graphData !== null && !fetchingGraphData">
        <!-- Header -->
        <div slot="header" class="clearfix">
          <span>Revenue Breakdown</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="exportCsv">
            <i class="fa fa-download"></i>
          </el-button>
        </div>
        <!-- Table -->
        <el-table class="eventTable" :data="tableData" border style="width: 100%" height="400">
          <el-table-column :fixed="index == 0" :prop="column.prop" :class="{ propertyColumn: index == 0 }" :label="column.headerName" :key="column.prop" :width="column.width" v-for="(column, index) in tableColumns"> </el-table-column>
        </el-table>
      </el-card>

      <!-- Select chart Message -->
      <div class="noChartDataDiv" v-else>
        <div class="title">Set revenue parameters to get started.</div>
      </div>
    </div>

    <DashboardSelectorComponent ref="dashboardSelector" v-bind:cardType="chartType" v-bind:cardParams="chartParams"> </DashboardSelectorComponent>
  </div>
</template>

<style lang="scss" src="./revenue.scss"></style>

<script>
import revenueComponent from './revenueComponent';
export default revenueComponent;
</script>
