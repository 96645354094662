import eventModule from '../../../services/event';
import companyModule from '../../../services/company';
import FiltersMixin from '../../../mixins/filtersMixins';
import BeatLoader from '@/components/loaders/BeatLoader.vue';
const _ = require('lodash');

export default {
  name: 'eventFilter',
  props: {
    showGroupBy: {
      default: true,
      required: false
    }
  },
  components: { BeatLoader },
  mixins: [FiltersMixin],
  data() {
    return {
      fetchingData: true,

      revenueEventName: null,
      revenueEvent: null,

      // Event & Session prop list
      eventList: null,
      eventMetadata: null,
      propertyList: null,
      subFilterForSuggestion: null
    };
  },

  methods: {
    //#region ----------------------------- Query Builder methods --------------------

    // Fetch event list from server
    async fetchEventList() {
      try {
        this.fetchingData = true;

        let result = await eventModule.getEventList(this);

        this.eventList = [];
        let listFromServer = Object.keys(result.data.data.eventList);
        for (var i = 0; i < listFromServer.length; i++) {
          if (this.grwDefaultEventList.indexOf(listFromServer[i]) < 0) {
            this.eventList.push(listFromServer[i]);
          }
        }

        // Remove all events which are hard coded growlytics events.
        this.eventMetadata = result.data.data.eventList;
        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Something went wrong.');
        this.fetchingData = false;
      }
    },

    onEventSelect() {
      this.revenueEvent = {
        name: this.revenueEventName,
        property: null,
        groupBy: null,
        filters: []
      };
      this.propertyList = this.eventMetadata[this.revenueEventName];
    },

    // ON +where button click
    addPropertyFilter() {
      this.revenueEvent.filters.push({
        propertyName: null,
        propertyInfo: null,
        comparisionTypeList: null,
        comparisionType: null,
        value: null
      });
    },

    // delete event from selectedEventList
    deleteEventFilter(index) {
      this.selectedEventList.splice(index, 1);
      if (this.selectedEventList.length == 0) {
        this.currentEvent = {
          name: null,
          property: null
        };
      }
    },

    // ON property dropdown change
    onFilterPropertyChange(filter) {
      // Read proeprty object from property name
      filter.propertyInfo = _.find(this.propertyList, (o) => {
        return o.name == filter.propertyName;
      });

      // Read property's datatype
      let propertyType = filter.propertyInfo.type;
      console.log('data type', propertyType);
      filter.comparisionTypeList = this.getComparisonsForDatatype(propertyType);
      filter.comparisionType = filter.comparisionTypeList[0].key;
    },

    // delete property filter from selectedEventList.filters
    deletePropertyFilter(propertyFilterList, index) {
      propertyFilterList.splice(index, 1);
    },

    //#endregion ----------------------------- Query Builder methods --------------------

    // Fetch event property suggestions
    async fetchEventPropertySuggestion(queryString, cb) {
      try {
        let params = {
          eventName: this.revenueEvent.name,
          property: this.subFilterForSuggestion.propertyName,
          queryString: queryString
        };
        let result = await eventModule.fetchCustomEventValueSuggetionsList(params);
        if (result.data.success) {
          var links = result.data.data;
          var results = queryString ? links.filter(this.createFilter(queryString)) : links;
          cb(results);
        }
      } catch (e) {
        cb();
        this.reportError(e);
      }
    },

    createFilter(queryString) {
      return (link) => {
        return link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },

    clearFilters() {
      if (this.revenueEvent) {
        this.revenueEvent.filters = [];
      }
    },

    async fetchDefaultRevenueTrackingEvents() {
      try {
        let result = await companyModule.getDefaultEventSettings('revenue');
        if (result.data) {
          this.revenueEventName = result.data.event;
          this.onEventSelect();
          this.revenueEvent.property = result.data.property;
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Failed to fetch revenue tracking settings. Something went wrong.`);
      }
    }
  },

  async mounted() {
    await this.fetchEventList();
    this.fetchDefaultRevenueTrackingEvents();
  }
};
