import eventModule from '../../services/event';
import FiltersMixin from '../../mixins/filtersMixins';
import ChartPageSelector from '@/components/chartPageSelector/chartPageSelector';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import DashboardSelectorComponent from './../dashboardSelectorPopup/dashboardSelector';
import DynamicTimelineGraphComponent from '@/components/graphs/dynamicTimelineGraph/dynamicTimelineGraph';
import HorizontalBarGraph from '@/components/graphs/horizontalBarGraph/horizontalBarGraph';
import DynamicBarGraph from '@/components/graphs/dynamicBarGraph/dynamicBarGraph';
import RevenueFilterComponent from '@/components/chartFilters/revenueFilter/revenueFilter';
const _ = require('lodash');
const moment = require('moment');

export default {
  name: 'revenuePage',
  data() {
    return {
      timezone: '+5:30',

      // Event info properties
      graphData: null,
      tableData: null,
      tableColumns: null,

      groupByDuration: 'day',
      groupByDurationList: [
        {
          key: 'Hourly',
          value: 'hour'
        },
        {
          key: 'Daily',
          value: 'day'
        },
        {
          key: 'Weekly',
          value: 'week'
        },
        {
          key: 'Monthly',
          value: 'month'
        }
      ],

      fetchingGraphData: false,

      // Chart options
      // chartType: "dynamic_horizontal_bar_graph",
      chartType: 'dynamic_timeline_graph',
      chartResultType: 'events',
      chartParams: null,
      dynamicTimelineChartOptions: null,
      dynamicBarChartOptions: null,
      horizontalBarChartOptions: null,
      selectedEventListCount: 0,
      // selectedEventList: null,

      // Timing properties
      startTime: null,
      endTime: null,
      timingFilter: '10080',
      dateFilterValues: []
    };
  },
  watch: {
    timingFilter: {
      handler: function () {
        if (this.timingFilter) {
          this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
          this.groupByDuration = this.groupByDurationList[0].value;
          this.fetchGraphData();
        }
      }
    }
  },
  mixins: [FiltersMixin],
  components: {
    ChartPageSelector,
    RevenueFilterComponent,
    UserPropertyFilterComponent,
    DashboardSelectorComponent,
    DynamicTimelineGraphComponent,
    DynamicBarGraph,
    HorizontalBarGraph
  },
  methods: {
    showSelectDashboardPopup() {
      this.$refs.dashboardSelector.dialogFormVisible = true;
    },

    //#region ----------------------------- Graph render methods --------------------

    drawGraph() {
      let durationType = 'latest';
      if (this.timingFilter == '') durationType = 'exact';

      if (this.chartType == 'dynamic_bar_graph') {
        this.dynamicBarChartOptions = {
          data: this.graphData,
          durationGroup: this.groupByDuration,
          durationType: durationType,
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
          duration: this.timingFilter
        };
        // console.log(JSON.stringify(this.dynamicBarChartOptions));
        this.fetchingGraphdata = false;
      } else if (this.chartType == 'dynamic_timeline_graph') {
        this.dynamicTimelineChartOptions = {
          data: this.graphData,
          durationGroup: this.groupByDuration,
          durationType: durationType,
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
          duration: this.timingFilter
        };
        console.log(JSON.stringify(this.dynamicTimelineChartOptions));
      }

      if (this.graphData) {
        this.putGraphDataInTable(this.graphData);
      }
    },

    exportCsv() {
      if (this.chartType == 'dynamic_bar_graph') {
        this.$refs.barGraph.exportCSV();
      } else if (this.chartType == 'dynamic_timeline_graph') {
        this.$refs.timelineGraph.exportCSV();
      } else if (this.chartType == 'dynamic_horizontal_bar_graph') {
        this.$refs.barGraph.exportCSV();
      }
    },

    putGraphDataInTable(rawData) {
      this.tableColumns = [];
      this.tableData = [];

      if (this.chartType == 'dynamic_horizontal_bar_graph' || this.chartType == 'dynamic_bar_graph') {
        this.tableColumns.push({
          headerName: 'Event/Attribute',
          prop: 'name',
          fiexed: true
        });
        this.tableColumns.push({
          headerName: 'Total',
          prop: 'total',
          fiexed: false
        });

        Object.keys(rawData).forEach((propertyName) => {
          this.tableData.push({
            name: propertyName,
            total: rawData[propertyName]
          });
        });
      } else {
        // Add proeprties column
        this.tableColumns.push({
          headerName: 'Event/Attribute',
          prop: 'name',
          width: '200',
          fiexed: true
        });

        // Iterate and build rows for property list

        Object.keys(rawData).forEach((propertyName) => {
          this.tableData.push({
            name: propertyName
          });
        });

        // Generate list of date columns from start time to end time
        let currentTime = this.startTime.clone().startOf(this.groupByDuration);
        while (currentTime <= this.endTime) {
          let endTime = currentTime.clone().add(1, this.groupByDuration + 's');

          // Create column for given date
          let headerName = currentTime.clone().format('MMM DD, HH A');
          this.tableColumns.push({
            headerName: headerName,
            prop: headerName,
            width: 150,
            fiexed: false
          });
          // For given date, add value for all table rows
          this.tableData.forEach((row) => {
            let propertyName = row.name;

            //Read count of events for current property and time
            let count = 0;
            let entry = _.find(this.graphData[propertyName], (xy) => {
              xy.x = moment.utc(xy.x).format('YYYY-MM-DDTHH:mm:ss');
              return moment(xy.x) >= currentTime && moment(xy.x) < endTime;
            });

            if (entry != null) count = entry.y;

            // Add column value for current row
            row[headerName] = count;
          });
          currentTime = endTime;
        }
      }
    },

    fetchGraphData() {
      // Remove old graph data
      this.graphData = null;
      this.revenueEvent = this.$refs.revenueFilterRef.revenueEvent;
      let errorMsg = null;
      if (!this.revenueEvent) {
        errorMsg = 'Please Select Revenue Event.';
      } else if (!this.revenueEvent.property) {
        errorMsg = 'Please Select Revenue Property.';
      }

      if (errorMsg) {
        this.warningToast(errorMsg);
        this.dynamicTimelineChartOptions = null;
        this.horizontalBarChartOptions = null;
        this.dynamicBarChartOptions = null;
        return;
      }

      // Process filters
      let propertyFilterList = [];
      this.revenueEvent.filters.forEach((propertyFilter) => {
        if (propertyFilter.propertyInfo == null || propertyFilter.value == null) {
          return;
        }
        propertyFilterList.push({
          comparisionType: propertyFilter.comparisionType,
          property: propertyFilter.propertyName,
          value: propertyFilter.value
        });
      });

      let eventInfo = {
        eventName: this.revenueEvent.name,
        revenueProperty: this.revenueEvent.property,
        groupByList: this.revenueEvent.groupBy ? [this.revenueEvent.groupBy] : [],
        filters: propertyFilterList
      };

      // Build timing filters
      if (this.timingFilter === '') {
        this.startTime = moment(this.dateFilterValues[0]);
        this.endTime = moment(this.dateFilterValues[1]);
        this.durationType = 'exact';
      } else {
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
        this.durationType = 'latest';
      }

      // Read user filters
      let userFilters = this.$refs.userFilterComponent.getFilters();
      console.log('user filters', userFilters);

      let params = {
        filters: {
          eventFilters: [eventInfo],
          userFilters: userFilters
        },
        startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
        durationGroup: this.groupByDuration,
        durationType: this.durationType,
        duration: this.timingFilter,
        resultType: this.chartResultType
      };
      this.chartParams = params;

      if (this.chartType == 'dynamic_bar_graph' || this.chartType == 'dynamic_horizontal_bar_graph') {
        // Send request to server
        this.fetchingGraphData = true;
        eventModule
          .getEventDynamicBarGraphData(params, this)
          .then((result) => {
            this.graphData = result.data.data;
            this.drawGraph();
            this.fetchingGraphData = false;
          })
          .catch((err) => {
            this.fetchingGraphData = false;
            this.reportError(err);
          });
      } else if (this.chartType == 'dynamic_timeline_graph') {
        // Send request to server
        this.fetchingGraphData = true;
        eventModule
          .getEventDynamicTimelineGraphData(params, this)
          .then((result) => {
            this.graphData = result.data.data;
            this.drawGraph();
            this.fetchingGraphData = false;
          })
          .catch((err) => {
            this.fetchingGraphData = false;
            this.errorToast('Something is wrong, please contact support.');
            this.reportError(err);
          });
      }
    }

    //#endregion ----------------------------- Graph render methods --------------------
  },

  created() {
    this.dateFilterValues = [moment().subtract(7, 'days').valueOf(), moment()];
    this.timezone = this.$store.state.token.timezone ? this.$store.state.token.timezone : '+5:30';
    this.startTime = moment().subtract(this.timingFilter, 'minutes');
    this.endTime = moment();

    // set group by list
    this.groupByDurationList = this.getGroupByDurationList(this.timingFilter);
    this.groupByDuration = this.groupByDurationList[0].value;
    // this.$router.go(this.$router.currentRoute)
  }
};
